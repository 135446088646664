<template>
    <v-container>

        <validation-observer tag="div" ref="observer">
            <v-dialog v-model="showLogin"
                      persistent
                      max-width="800"
                      :fullscreen="$vuetify.breakpoint.xsOnly"
            >
                <v-card class="mb-3">
                    <v-card-title class="text-h5">
                        Login
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="email"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="form.email"
                                        outlined
                                        label="Email"
                                        :error-messages="errors"
                                        required
                                    ></v-text-field>
                                </validation-provider>

                                <validation-provider
                                    v-slot="{ errors }"
                                    name="password"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="form.password"
                                        outlined
                                        type="password"
                                        label="Passwort"
                                        :error-messages="errors"
                                        required
                                        @keydown.enter="login"
                                    ></v-text-field>
                                </validation-provider>

                                <v-btn
                                    @click="login"
                                    color="secondary"
                                    class="mr-4"
                                    elevation="2"
                                >Log in
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </validation-observer>
    </v-container>
</template>

<script>

export default {
    name: 'Login',
    data: () => ({
        showLogin: true,
        form: {},
    }),
    mounted() {
        this.$root.$children[0].hideLoading()
    },
    methods: {
        login() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$root.$children[0].showLoading()

                    window.axios.post(process.env.VUE_APP_API_URL + '/travel-box/login',this.form)
                        .then(response => {
                            if(response.data.status) {
                                localStorage.setItem('token',response.data.token)
                                window.axios.defaults.headers.AuthToken = response.data.token
                                this.$router.push('/profile/booking')
                            } else {
                                this.$refs['observer'].setErrors({
                                    'email': 'Wrong credentials',
                                })
                            }
                        })
                        .finally(()=>{
                            this.$root.$children[0].hideLoading()
                        })
                }
            })
        }
    },
}
</script>

<style>
</style>